import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { entities } from 'app/entity';
import { AccreditationsRole } from 'app/models/Accreditation';
import Badge from 'core/components/Badge';
import { FlexRow } from 'core/components/FlexUtils';
import Link from 'core/components/Link';
import Spacer from 'core/components/Spacer';
import Text from 'core/components/Text';
import PopoverCellRenderer from 'core/containers/DataGrid/CellRenderers/PopoverCellRenderer';
import { t } from 'core/i18n';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';
import { DcoInvoiceTest, DcoInvoiceTestParticipant } from 'finance/models/DCOInvoice';
import { useNotAdultIcon } from 'personnel/containers/AthletesPage/useNotAdultIcon';

import { Item } from '../components/Items/Items';

export const getParticipantsMap = (role: AccreditationsRole) => {
  switch (role) {
    case 'dco':
      return t('DCO');
    case 'leadDco':
      return t('Lead DCO');
    case 'bco':
      return t('BCO');
    case 'chaperone':
      return t('Chaperone');
    default:
      return null;
  }
};

const roleOrder = ['leadDco', 'dco', 'bco', 'chaperone'];

const sortByRoleImportance = (items: { roles: AccreditationsRole[] }[]) => {
  return items.sort((a, b) => {
    const aIndex = a.roles
      ?.map((role) => roleOrder.indexOf(role))
      .filter((i) => i !== -1)
      .sort()[0];
    const bIndex = b.roles
      ?.map((role) => roleOrder.indexOf(role))
      .filter((i) => i !== -1)
      .sort()[0];
    return aIndex - bIndex;
  });
};

const getParticipants = (participants: DcoInvoiceTestParticipant[], id: number) => {
  const sortedParticipants = sortByRoleImportance(participants) as DcoInvoiceTestParticipant[];

  const rows = sortedParticipants.slice(0, 3).map((p) => {
    const roles = p.roles
      ?.map((r) => ({
        role: r,
        order: roleOrder.indexOf(r),
      }))
      .filter((item) => item.order !== -1)
      .sort((a, b) => a.order - b.order)
      .map((item) => (
        <Spacer $marginLeft="0.1rem" $display="inline" key={item.role}>
          <Badge variant="primary">{getParticipantsMap(item.role)}</Badge>
        </Spacer>
      ));
    return (
      <FlexRow spacing="0.1rem">
        <Text>{p.fullName}</Text>
        <Spacer $marginLeft="0.3rem">{roles}</Spacer>
      </FlexRow>
    );
  });

  return [
    rows,
    sortedParticipants.length > 3 ? (
      <Link to={entities.test.urls().detail(id) + '/team'} text={t('Show all')} />
    ) : null,
  ];
};

const useTestPopover = (test?: DcoInvoiceTest) => {
  const formatDateTime = useFormatDateTime();
  const notAdultIcon = useNotAdultIcon();

  const icon = notAdultIcon(
    DateTime.fromJSDate(new Date(test?.athletesDateOfBirth ? test?.athletesDateOfBirth : '')),
    DateTime.fromJSDate(new Date(test?.dateFrom ? test?.dateFrom : ''))
  );

  return useCallback(
    (item: Item) => {
      return (
        <PopoverCellRenderer
          options={{
            title: (
              <FlexRow>
                {icon} {item.testsId}
              </FlexRow>
            ),
            link: item.testsId !== null ? entities.test.urls().detail(item.testsId) : '',
            items: [
              {
                label: t('City (Country)'),
                value: test?.testResultsLocation || '',
              },
              {
                label: t('Notification Time'),
                value: test ? formatDateTime(new Date(test.notificationAt), 'DATETIME_SHORT') : '',
              },
              {
                label: t('Completion Time'),
                value: test ? formatDateTime(new Date(test.doneDate), 'DATETIME_SHORT') : '',
              },
              {
                label: t('Team members'),
                value:
                  test && test?.participants && test?.participants?.length > 0
                    ? getParticipants([...test.participants], item.testsId!)
                    : '',
              },
            ],
          }}
        >
          <>
            {!!item.testsId && (
              <Link to={entities.test.urls().detail(item.testsId)} text={item.testsId} />
            )}
          </>
        </PopoverCellRenderer>
      );
    },
    [formatDateTime, icon, test]
  );
};

export default useTestPopover;
