import { getIn, useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AccreditationsRole } from 'app/models/Accreditation';
import { FlexCell } from 'core/components/FlexUtils';
import Switch from 'core/components/Switch';

import { TeamMemberType } from '../../enums';
import { TeamFormData, TeamMemberData } from '../../useTeamInputMapping';
import { ErrorIcon } from '../MembersCardItem/cells/RolesCell/styled';

import AllToggle from './AllToggle';
import { RoleItems, ThPlaceholder, Toggle } from './styled';

interface Props {
  /**
   * Disable remove assigned roles
   * that is applied for "already saved" roles
   * so user can add / remove roles until Save button is hit
   */
  disableRemoveAssignment: boolean;
  /**
   * prefix for array field like "teamMembers" or "users"
   * its used for "select all" action
   */
  fieldPrefix: TeamMemberType;
  /** Readonly mode */
  isViewMode?: boolean;
}

/**
 * Checks if all items
 * that are possible to select (user has proper accreditation), are selected
 * * Chaperone is added in input mapping as default accreditation, see `getAllowedRolesFromAccreditations`
 */
function areAllSelected(values: TeamMemberData[], role: AccreditationsRole) {
  // 1. filter possible options
  const possibleOptions = values.filter((x) => x.allowedRoles.includes(role));

  return (
    // if there is more than 0 possible options
    possibleOptions.length > 0 &&
    // check if all are selected (nothing left)
    possibleOptions.filter((x) => !x.roles.includes(role)).length === 0
  );
}

const MembersCardHeader: FC<Props> = ({ fieldPrefix, isViewMode, disableRemoveAssignment }) => {
  const formik = useFormikContext<TeamFormData>();
  const { t } = useTranslation();

  const { setValues, values, initialValues } = formik;
  const usersData = getIn(values, fieldPrefix);

  const selectAll = (role: AccreditationsRole) => (value: boolean) => {
    setValues((formValues: TeamFormData) => {
      const values = { ...formValues };

      values[fieldPrefix] = values[fieldPrefix].map((u: TeamMemberData) => ({
        ...u,
        roles:
          disableRemoveAssignment &&
          initialValues[fieldPrefix].find((w) => w.usersId === u.usersId)?.roles.includes(role)
            ? u.roles
            : value && u.allowedRoles.includes(role)
              ? Array.from(new Set([...u.roles, role]))
              : u.roles.filter((x) => x !== role),
      }));

      return values;
    });
  };

  return (
    <>
      <FlexCell flex={4} minWidth="10rem">
        {/* Avatar with name and availability */}
        <ThPlaceholder> </ThPlaceholder>
      </FlexCell>
      <FlexCell flex={2} minWidth="5rem">
        {/* Status */}
        <ThPlaceholder> </ThPlaceholder>
      </FlexCell>
      <FlexCell flex={6} minWidth="15rem">
        <RoleItems>
          <ErrorIcon></ErrorIcon>
          <div>
            <span>{t('Lead DCO')}</span>
            <Toggle>
              <Switch name="allDCO" id="allDCO" label={''} onChange={() => {}} />
            </Toggle>
          </div>
          <div>
            <span>{t('DCO')}</span>
            <AllToggle
              areAllSelected={areAllSelected(usersData, AccreditationsRole.DCO)}
              value={areAllSelected(usersData, AccreditationsRole.DCO)}
              onChange={selectAll(AccreditationsRole.DCO)}
              role={AccreditationsRole.DCO}
              isDisabled={!!isViewMode}
            />
          </div>
          <div>
            <span>{t('BCO')}</span>
            <AllToggle
              areAllSelected={areAllSelected(usersData, AccreditationsRole.BCO)}
              value={areAllSelected(usersData, AccreditationsRole.BCO)}
              onChange={selectAll(AccreditationsRole.BCO)}
              role={AccreditationsRole.BCO}
              isDisabled={!!isViewMode}
            />
          </div>
          <div>
            <span>{t('Chaperone')}</span>
            <AllToggle
              areAllSelected={areAllSelected(usersData, AccreditationsRole.CHAPERONE)}
              value={areAllSelected(usersData, AccreditationsRole.CHAPERONE)}
              onChange={selectAll(AccreditationsRole.CHAPERONE)}
              role={AccreditationsRole.CHAPERONE}
              isDisabled={!!isViewMode}
            />
          </div>
        </RoleItems>
      </FlexCell>
      <FlexCell flex={2}>
        {/* Actions */}
        <ThPlaceholder> </ThPlaceholder>
      </FlexCell>
      {!isViewMode && (
        <FlexCell flex={1} maxWidth="3rem" minWidth="3rem">
          {/* Actions */}
        </FlexCell>
      )}
    </>
  );
};

export default MembersCardHeader;
