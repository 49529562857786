import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AccreditationsRole } from 'app/models/Accreditation';
import Switch from 'core/components/Switch';
import Tooltip from 'core/components/Tooltip';

import { Toggle } from './styled';

const AllToggle: FC<{
  onChange: (v: boolean) => void;
  role: AccreditationsRole;
  areAllSelected: boolean;
  isDisabled: boolean;
  value: boolean;
}> = ({ isDisabled, value, role, onChange }) => {
  const { t } = useTranslation();

  const toggle = (
    <Toggle>
      <Switch
        name={`toggle-all-${role}`}
        id={`toggle-all-${role}`}
        disabled={isDisabled}
        onChange={onChange}
        value={value}
        label={''}
      />
    </Toggle>
  );

  if (isDisabled) {
    return toggle;
  }

  return (
    <Tooltip
      content={
        value
          ? t('Disable Role for all users. (All with available Accreditation)')
          : t('Enable Role for all users. (All with available Accreditation)')
      }
    >
      {toggle}
    </Tooltip>
  );
};

export default AllToggle;
