import { useFormikContext } from 'formik';
import { FC, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { entities } from 'app/entity';
import { AccreditationsRole } from 'app/models/Accreditation';
import { FeedbackMessage } from 'core/components/FeedbackMessage';
import { FormContext } from 'core/components/Form';
import { Row } from 'core/components/Grid';
import { FullRowCell, HalfRowCell } from 'core/components/GridCell';
import Modal from 'core/components/Modal';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import { OTHER_NUMBER } from 'core/components/SelectOtherField';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import useAppSelector from 'core/hooks/useAppSelector';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useGetPermission } from 'core/hooks/usePermission';
import { useResources } from 'core/hooks/useResource';
import LinkedFinancesPaper from 'finance/components/LinkedFinancesPaper';
import { unloadMission } from 'planning/actions';
import AnalysesFields from 'planning/components/AnalysesFields';
import AssignmentFieldset from 'planning/components/AssignmentFieldset';
import Comments from 'planning/components/Comments';
import ControlLocationFields from 'planning/components/ControlLocationFields';
import ScheduleFieldset from 'planning/components/ScheduleFieldset';
import Mission from 'planning/models/Mission';
import { TeamMemberStatus } from 'planning/models/TeamMember';
import Test, { TestStatus } from 'planning/models/Test';

import useIncludeOverride from '../../hooks/useIncludeOverride';
import { TestFormData } from '../../hooks/useTestSchema';
import { includeResourceMapping } from '../../TestForm/resourceMap';

import BasicInformationFieldset from './BasicInformationFieldset';
import PreviousDeclarations from './PreviousDeclarations';

export const DetailTab = () => <Detail />;
export const DetailBulk = () => <Detail editMode />;
export const DetailIndividualBulk: FC<{ entityData: Test }> = ({ entityData }) => (
  <Detail oneColumn disableAdvancedFeatures editMode entityData={entityData} />
);

interface Props {
  oneColumn?: boolean;
  disableAdvancedFeatures?: boolean;
  editMode?: boolean;
  entityData?: Test;
}

const Detail: FC<Props> = ({ oneColumn, editMode, entityData, disableAdvancedFeatures }) => {
  const detailData = useDetail<Test>();
  const pageMode = detailData.mode;
  const testData = detailData?.entityData || entityData;
  const [, setIncludesOverride] = useIncludeOverride();
  const state = useContext(FormContext);
  const { t } = useTranslation();
  const hasPermission = useGetPermission();
  const canEditDetails = hasPermission('tests:patch[actions:details]');
  const canSeeDetails = hasPermission('tests:get[actions:details]');
  const canSeeAnalyses = hasPermission('tests:get[actions:analyses]');
  const canSeeTeam = hasPermission('tests:get[actions:team]');
  const canFindAthletes = hasPermission('athletes:find');
  const { setFieldValue, setTouched, touched, values, setValues } =
    useFormikContext<TestFormData>();
  const { missionsId, bloodAnalyses, otherBloodAnalyses, dbsAnalyses, otherDbsAnalyses } = values;
  const mode = editMode ? 'edit' : pageMode;
  const isClone = mode === 'clone';
  // These missions will be automatically loaded by the dropdown that opens them - they have the same map
  const { data: missions } = useResources<Mission>('missions', { autoload: false });
  const missionIncludes = useIncludeOptions('missionsId') as Mission[];
  const missionInclude = Array.isArray(missionIncludes) && missionIncludes[0];
  const missionChanged = missionsId !== testData?.missionsId;
  const isViewModeActive = Boolean(state.viewMode && state.defaultViewMode);
  const preloadedMission = useAppSelector(({ planning }) => planning.missionDetail);
  const dispatch = useDispatch();
  const user = useAppSelector(({ core }) => core.user);
  const showAnalysesToAssignedDco =
    useBoolClientOption('showAnalysesToAssignedDco') &&
    !!(testData?.teamMembers || []).find((it) => {
      return (
        it.status === TeamMemberStatus.CONFIRMED &&
        it.usersId === user?.id &&
        (it.roles || []).includes(AccreditationsRole.DCO)
      );
    });

  const getMission = useCallback(() => {
    // Mission preloaded for prefill has precedence
    if (preloadedMission) return preloadedMission;

    // If we have no mission selected, well ... there is no mission :)
    if (!missionsId) return undefined;

    // If we have include and mission hasn't changed. we should use it
    if (!missionChanged && missionInclude) return missionInclude;

    // Otherwise we can find it in options
    return (missions || []).find((i) => i.id === missionsId);
  }, [missions, missionsId, missionChanged, missionInclude, preloadedMission]);
  const [mission, setMission] = useState(getMission());

  const advancedDeclarationsEnabled = useBoolClientOption('enableAdvancedDeclarations');

  const showDeclarations =
    canFindAthletes &&
    (testData?.use3genApp || advancedDeclarationsEnabled) &&
    // We want to ignore the status if we're cloning the test
    (isClone ||
      !testData?.status ||
      [TestStatus.ASSIGNED, TestStatus.IN_PROGRESS, TestStatus.UNASSIGNED].includes(
        testData.status
      ));

  const [confirmMissionChangeData, setConfirmMissionChangeData] =
    useState<null | Partial<TestFormData>>(null);

  // Called when mission change is started but not confirmed
  const handleMissionChange = (newValues: Partial<TestFormData>) => {
    if (newValues.missionsId === OTHER_NUMBER || !newValues.missionsId) {
      applyMissionChangesToForm(newValues);
    } else {
      setConfirmMissionChangeData(newValues);
    }
  };

  const applyMissionChangesToForm = (newMissionData: Partial<TestFormData>) => {
    setValues({ ...values, ...newMissionData });
    setTouched(
      {
        ...touched,
        ...Object.keys(newMissionData).reduce((p, k) => ({ ...p, [k]: false }), {}),
      },
      // Validating after we've just changed values would cause problems with stale values
      false
    );
  };

  const updateIncludesUsingMission = (mission?: Mission) => {
    if (!mission) return;

    const newIncludes = {} as { [key: string]: unknown };
    Object.values(includeResourceMapping).forEach((key) => {
      // @ts-ignore This definition is unfortunately very loose
      if (mission[key]) newIncludes[key] = mission[key];
    });

    setIncludesOverride(newIncludes);
  };

  // Called when mission change is confirmed
  const handleConfirmMissionChange = (confirm: boolean) => {
    if (confirm) {
      if (!confirmMissionChangeData) return;
      const mission = getMission();

      updateIncludesUsingMission(mission);

      setValues({ ...values, ...confirmMissionChangeData });
      setTouched(
        {
          ...touched,
          ...Object.keys(confirmMissionChangeData).reduce((p, k) => ({ ...p, [k]: false }), {}),
        },
        // Validating after we've just changed values would cause problems with stale values
        false
      );

      setMission(mission);
    } else {
      dispatch(unloadMission());
    }
  };

  const hasEnabledTeamAndBackNumber = useBoolClientOption('enableTeamAndBackNumber');

  const leftColumn = (
    <Row>
      <FullRowCell>
        {/* TODO: solve resources  */}
        <BasicInformationFieldset
          hasEnabledTeamAndBackNumber={hasEnabledTeamAndBackNumber}
          handleMissionChange={handleMissionChange}
          setFieldValue={setFieldValue}
          entityData={entityData}
        />
      </FullRowCell>

      {(canSeeAnalyses || showAnalysesToAssignedDco) && (
        <AnalysesFields hideNonAnalysesFields={!(canSeeDetails || showAnalysesToAssignedDco)} />
      )}
    </Row>
  );

  const Cell = oneColumn ? FullRowCell : HalfRowCell;

  const missionDateFrom = mission?.dateFrom;
  const missionDateTo = mission?.dateTo;
  const missionRange =
    missionDateFrom instanceof Date && missionDateTo instanceof Date
      ? ([missionDateFrom, missionDateTo] as [Date, Date])
      : undefined;

  const scheduleMessageEl = (
    <FeedbackMessage $type="warning">
      {t('Selecting a future date will unarchive the test.')}
    </FeedbackMessage>
  );

  const displayScheduleMessage =
    testData?.archived && values.dateRange.to && values.dateRange.to > new Date();

  const hasBlood = bloodAnalyses.length > 0 || otherBloodAnalyses.length > 0;
  const hasDbs = dbsAnalyses.length > 0 || otherDbsAnalyses.length > 0;

  const rightColumn = (
    <Row>
      <FullRowCell>
        <ScheduleFieldset
          originEntity={entities.mission.name(t)}
          originRange={missionRange}
          originEvent={mission?.event}
          disableRangeExpanding={!canEditDetails}
          feedbackMessage={displayScheduleMessage && scheduleMessageEl}
        />
      </FullRowCell>

      {canSeeTeam && (
        <FullRowCell>
          <AssignmentFieldset
            disableAdvanced={disableAdvancedFeatures}
            hasBlood={hasBlood || hasDbs}
          />
        </FullRowCell>
      )}

      <FullRowCell>
        <ControlLocationFields
          athletesId={values.athletesId}
          locationPickerEnabled={!isViewModeActive}
        />
      </FullRowCell>

      {canSeeDetails && (
        <LinkedFinancesPaper<TestFormData>
          showBillableOptions={canEditDetails}
          dcoInvoices={testData?.dcoInvoices}
          createNewCostLink={
            mode === 'edit' && testData
              ? entities.cost.urls().createFromTests([testData.id])
              : undefined
          }
          showInvoicingCode={canEditDetails}
        />
      )}

      <FullRowCell>
        <Comments canSeeSensitiveComments={canSeeDetails} />
      </FullRowCell>

      {showDeclarations && (
        <FullRowCell>
          <PreviousDeclarations />
        </FullRowCell>
      )}
    </Row>
  );

  return (
    <>
      <Row>
        <Cell>{leftColumn}</Cell>
        <Cell>{rightColumn}</Cell>
      </Row>

      <Modal
        ariaLabel={t('Unsaved data confirmation dialog')}
        onConfirm={() => handleConfirmMissionChange(true)}
        onCancel={() => handleConfirmMissionChange(false)}
        onClose={() => setConfirmMissionChangeData(null)}
        open={!!confirmMissionChangeData}
      >
        {t(
          'You can overwrite filled data by changing the Mission. Are you sure you want to continue?'
        )}
      </Modal>
    </>
  );
};

export default Detail;
