import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AccreditationsRole } from 'app/models/Accreditation';
import DataCard from 'core/components/DataCard';
import { useFormViewMode } from 'core/components/Form';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { AnalysesFieldsData } from 'planning/components/AnalysesFields';

import { TeamMemberType } from '../enums';
import { TeamFormData } from '../useTeamInputMapping';

import MembersCardFooter from './MembersCardFooter';
import MembersCardHeader from './MembersCardHeader';
import MembersCardItem from './MembersCardItem';
import useActions from './useActions';

interface Props {
  availabilities: { [usersId: number]: string };
  advancedAssignmentEnabled: boolean;
  disableRemoveAssignment?: boolean;
  type: TeamMemberType;
  showStatus: boolean;
}

const MembersCard: FC<Props> = ({
  disableRemoveAssignment = false,
  advancedAssignmentEnabled,
  availabilities,
  showStatus,
  type,
}) => {
  const { mode } = useDetail();
  const { initialValues, values } = useFormikContext<
    AnalysesFieldsData & TeamFormData & { dateRange: { from: Date; to: Date } | null } // TODO: this is 2 wide union, so reusable Team tab component depends on outside data (analysis)
  >();
  const { t } = useTranslation();
  const data = values[type];

  const alreadySavedUsersId = useMemo(
    () =>
      mode !== 'edit'
        ? []
        : (initialValues.assignedMembers || [])
            .concat(initialValues.invitedMembers || [])
            .map((i) => i.usersId),
    [initialValues.assignedMembers, initialValues.invitedMembers, mode]
  );

  const isTypeInvited = type === TeamMemberType.INVITED;
  const isViewMode = useFormViewMode(type);

  const actionsDisabled = !!isViewMode && !disableRemoveAssignment;
  const [sortedData, idIndexMap] = useMemo(() => {
    const teamMembersMap = new Map<number, number>();

    const sortedData = data
      .map((m, index) => {
        teamMembersMap.set(m.usersId, index);
        return { ...m, id: m.usersId };
      })
      .sort((b, a) =>
        b.fullName ? b.fullName.localeCompare(a.fullName, undefined, { sensitivity: 'variant' }) : 0
      );

    return [sortedData, teamMembersMap];
  }, [data]);

  const [actions, triggers, modals] = useActions(
    type,
    advancedAssignmentEnabled,
    disableRemoveAssignment ? alreadySavedUsersId : undefined
  );

  const isLeadDCOSaved = !!initialValues.assignedMembers
    .concat(initialValues.invitedMembers)
    .find((u) => u.alreadySavedRoles?.includes(AccreditationsRole.LEAD_DCO));

  const hasBloodAnalysis =
    Number(values.bloodAnalyses?.length) > 0 ||
    Number(values.otherBloodAnalyses?.length) > 0 ||
    Number(values.dbsAnalyses?.length) > 0 ||
    Number(values.otherDbsAnalyses?.length) > 0;

  return (
    <>
      <DataCard
        title={isTypeInvited ? t('Invited') : t('Assigned')}
        header={
          sortedData.length > 0 && (
            <MembersCardHeader
              disableRemoveAssignment={disableRemoveAssignment}
              isViewMode={!!isViewMode}
              fieldPrefix={type}
            />
          )
        }
        actions={isViewMode ? undefined : actions}
        footer={
          !isViewMode && (
            <MembersCardFooter
              actionsDisabled={actionsDisabled}
              isViewMode={!!isViewMode}
              onAdd={triggers.onAdd}
              type={type}
            />
          )
        }
        items={sortedData}
        type="field"
      >
        {(user) => (
          <MembersCardItem
            disableChangeLeadDco={disableRemoveAssignment && isLeadDCOSaved}
            advancedAssignmentEnabled={advancedAssignmentEnabled}
            hasBloodAnalysis={hasBloodAnalysis}
            actionsDisabled={actionsDisabled}
            index={idIndexMap.get(user.id)!}
            availabilities={availabilities}
            showStatus={showStatus}
            isViewMode={!!isViewMode}
            triggers={triggers}
            fieldPrefix={type}
            type={type}
            user={user}
            disableRemoveRoles={
              disableRemoveAssignment && alreadySavedUsersId.includes(user.usersId)
                ? user.alreadySavedRoles || undefined
                : []
            }
          />
        )}
      </DataCard>

      {modals}
    </>
  );
};

export default MembersCard;
