import styled from 'styled-components'; //macro

import theme from 'app/theme';

export const Toggle = styled.div`
  transform: scale(0.75);
`;

export const RoleItems = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;

  &[aria-readonly='true'] ${Toggle} {
    cursor: auto;
    visibility: hidden;
    pointer-events: none;
  }

  & > div {
    flex: 1;
    margin: 0;
    gap: 0.25rem;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 0.875rem;
    flex-direction: column;
    justify-content: center;

    /* Hide first switch to keep item height same */
    &:nth-of-type(2) ${Toggle} {
      cursor: auto;
      visibility: hidden;
      pointer-events: none;
    }

    span {
      color: ${theme.color.gray400};
    }
  }
`;

export const ThPlaceholder = styled.div`
  min-height: 3rem;
`;
