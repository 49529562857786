import { useField, useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AccreditationsRole } from 'app/models/Accreditation';
import theme from 'app/theme';
import { useFormViewMode } from 'core/components/Form';
import MaterialIcon from 'core/components/MaterialIcon';
import Tooltip from 'core/components/Tooltip';
import { TeamMemberType } from 'planning/components/TeamTab/enums';
import { TeamFormData, TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';

import RoleCellItem from './RoleCellItem';
import { ErrorIcon, Wrapper } from './styled';

interface Props {
  disableRemoveRoles?: AccreditationsRole[];
  disableChangeLeadDco?: boolean;
  fieldPrefix: TeamMemberType;
  hasBloodAnalysis: boolean;
  index: number;
}

const RolesCell: FC<Props> = ({
  disableChangeLeadDco,
  disableRemoveRoles,
  hasBloodAnalysis,
  fieldPrefix,
  index,
}) => {
  const { setValues } = useFormikContext<TeamFormData>();
  const isViewMode = useFormViewMode(`${fieldPrefix}`);
  const fieldName = `${fieldPrefix}[${index}]`;
  const { t } = useTranslation();

  const [{ value }, { error }] = useField<TeamMemberData>(fieldName);

  const selectedRoles = [...(value.roles || [])];

  const setRole = (role: AccreditationsRole, toggle: boolean) => {
    setValues((formValues) => {
      const values = { ...formValues };
      values[fieldPrefix] = [...values[fieldPrefix]];

      if (role === AccreditationsRole.LEAD_DCO) {
        [TeamMemberType.ASSIGNED, TeamMemberType.INVITED].forEach((key) => {
          values[key] = values[key].map((u) => ({
            ...u,
            roles: u.roles.filter((r) => r !== AccreditationsRole.LEAD_DCO),
          }));
        });
      }

      if (toggle) {
        values[fieldPrefix][index] = {
          ...values[fieldPrefix][index],
          roles: Array.from(
            new Set([
              ...values[fieldPrefix][index].roles,
              role, // Add role
              // If addition is LEAD and has DCO Accreditation select DCO Role 2
              role === AccreditationsRole.LEAD_DCO &&
                values[fieldPrefix][index].allowedRoles.includes(AccreditationsRole.DCO) &&
                AccreditationsRole.DCO,
              // If addition is LEAD and has BCO Accreditation and test has Blood analysis select BCO role 2
              role === AccreditationsRole.LEAD_DCO &&
                hasBloodAnalysis &&
                values[fieldPrefix][index].allowedRoles.includes(AccreditationsRole.BCO) &&
                AccreditationsRole.BCO,
            ])
          ).filter(Boolean) as AccreditationsRole[],
        };
      } else {
        values[fieldPrefix][index] = {
          ...values[fieldPrefix][index],
          roles: values[fieldPrefix][index].roles.filter((r) => r !== role),
        };
      }

      return values;
    });
  };

  const missingRole = selectedRoles.length === 0;
  const props = { value, viewMode: !!isViewMode, disableRemoveRoles, setRole, fieldName };

  const errorMsg =
    (error && Object.values(error).join(', ')) ||
    (missingRole ? t('User have to have enabled at-least one role.') : undefined);

  return (
    <Wrapper>
      <ErrorIcon>
        {errorMsg && (
          <Tooltip content={errorMsg}>
            <MaterialIcon color={theme.color.error} icon="warning" />
          </Tooltip>
        )}
      </ErrorIcon>

      <RoleCellItem
        role={AccreditationsRole.LEAD_DCO}
        disableChange={disableChangeLeadDco}
        {...props}
      />

      <RoleCellItem role={AccreditationsRole.DCO} {...props} />

      <RoleCellItem role={AccreditationsRole.BCO} {...props} />

      <RoleCellItem role={AccreditationsRole.CHAPERONE} {...props} />
    </Wrapper>
  );
};

export default RolesCell;
