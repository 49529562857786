import { FC } from 'react';

import { AccreditationsRole } from 'app/models/Accreditation';
import Switch from 'core/components/Switch';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';

import RoleTooltip from './RoleTooltip';
import { RoleCell } from './styled';
interface Props {
  setRole: (role: AccreditationsRole, toggle: boolean) => void;
  disableRemoveRoles?: AccreditationsRole[];
  initialValue?: TeamMemberData;
  role: AccreditationsRole;
  disableChange?: boolean;
  value?: TeamMemberData;
  fieldName: string;
  viewMode?: boolean;
}

const RoleCellItem: FC<Props> = ({
  disableRemoveRoles,
  disableChange,
  fieldName,
  setRole,
  value,
  viewMode,
  role,
}) => {
  const isDisabled =
    disableChange ||
    (disableRemoveRoles && disableRemoveRoles.includes(role)) ||
    (value && !value.allowedRoles.includes(role));
  const selectedRoles = [...(value?.roles || [])];

  const cell = (
    <RoleCell>
      <Switch
        onChange={(value) => setRole(role, value)}
        value={selectedRoles.includes(role)}
        name={`${fieldName}.${role}`}
        id={`${fieldName}.${role}`}
        disabled={isDisabled}
        label={''}
      />
    </RoleCell>
  );

  if (viewMode) {
    return <>{cell}</>;
  }

  return (
    <RoleTooltip
      disableRemove={!!disableRemoveRoles?.includes(role)}
      disableChange={disableChange}
      value={value}
      role={role}
    >
      {cell}
    </RoleTooltip>
  );
};

export default RoleCellItem;
