import { useFormikContext } from 'formik';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { RolesId } from 'app/models/Role';
import User from 'app/models/User';
import DropDown, { Option, SimpleOption } from 'core/components/DropDown';
import Modal from 'core/components/Modal';
import { transformDateToString } from 'core/effects/apiCall/dateTimeTransformations';
import { stringify } from 'core/functions/qs';
import useAppSelector from 'core/hooks/useAppSelector';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useResources } from 'core/hooks/useResource';
import AvailabilityCategory from 'personnel/models/AvailabilityCategory';
import useOfficersReducer from 'planning/hooks/useOfficersReducer';
import { TeamMemberStatus } from 'planning/models/TeamMember';

import { TeamMemberType } from '../../enums';
import {
  TeamFormData,
  TeamMemberData,
  getAllowedRolesFromAccreditations,
} from '../../useTeamInputMapping';

interface Props {
  notRemovableUsersId?: number[];
  type: TeamMemberType;
  onClose: () => void;
  open: boolean;
}

const emptyData = [] as TeamMemberData[];

const AddModal: FC<Props> = ({ type, open, onClose, notRemovableUsersId }) => {
  const formikContext = useFormikContext<TeamFormData & { dateRange?: { from: Date; to: Date } }>();
  const { data: availabilityCategories, reload: loadAvailabilityCategories } =
    useResources<AvailabilityCategory>('profile/availabilities/categories', { autoload: false });
  const availabilitiesEnabled = useBoolClientOption('enableAvailabilities');
  const [selected, setSelected] = useState<number[]>([]);
  const { t } = useTranslation();

  const invitedMembers = formikContext.values.invitedMembers || emptyData;
  const assignedMembers = formikContext.values.assignedMembers || emptyData;
  const dateRange = formikContext.values.dateRange;

  useEffect(() => {
    if (!open) return;

    // Load categories if they are available
    if (availabilitiesEnabled) {
      loadAvailabilityCategories(false);
    }

    // Preselect currently filled members
    setSelected(
      type === TeamMemberType.ASSIGNED
        ? assignedMembers.map((m) => m.usersId)
        : invitedMembers.map((m) => m.usersId)
    );

    // We don't want to change the selection outside of opening
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const selectWithoutRemovingNotRemovableUsers = (selection: unknown) => {
    const newIds = selection && Array.isArray(selection) ? selection.map(Number) : [];
    const fixedIds = notRemovableUsersId || [];
    const oldIds = selected;

    const oldIdsSet = new Set(oldIds);
    const newIdsSet = new Set(newIds);

    // disable user deselected frozen ID from current list
    if (fixedIds.find((i) => oldIdsSet.has(i) && !newIdsSet.has(i))) {
      toast.error(t('Already saved team members can not be removed'));
      return;
    }

    setSelected(newIds);
  };

  const optionsReducer = useOfficersReducer({
    availabilityCategories,
    options: useMemo(
      () => ({
        beforeReducer: (users) => {
          const alreadyAssigned = new Set(assignedMembers.map((m) => m.usersId));
          return users.filter(
            (user) =>
              type === TeamMemberType.ASSIGNED ||
              !alreadyAssigned.has(user.id) ||
              user?.accreditations?.length! > 0
          );
        },
        afterReducer: (options) =>
          options.sort((b: Option, a: Option) =>
            typeof a !== 'string' && typeof b !== 'string' && b.name
              ? b.name.localeCompare(a.name, undefined, { sensitivity: 'variant' })
              : 0
          ),
      }),
      [assignedMembers, type]
    ),
  });

  const clientId = useAppSelector(
    (state) => (state.core.user && state.core.user.client.id) || null
  );

  const availabilityConditions = dateRange
    ? {
        availableFrom: transformDateToString(dateRange.from, 'DATE'),
        availableTo: transformDateToString(dateRange.to, 'DATE'),
      }
    : {};
  const { data: users } = useResources<User>(
    `users?${stringify({
      active: true,
      rolesId: RolesId.DCO,
      clientsId: clientId,
      ...availabilityConditions,
    })}`,
    { autoload: !!open }
  );
  const getOptions = () =>
    users
      ? optionsReducer(
          users.filter(
            (u) =>
              selected.includes(u.id) || !notRemovableUsersId || !notRemovableUsersId.includes(u.id)
          ),
          selected
        )
      : [];

  const options = useMemo<Option[]>(getOptions, [
    notRemovableUsersId,
    optionsReducer,
    selected,
    users,
  ]);

  const add = useCallback(
    (userIds: number[]) => {
      if (!Array.isArray(userIds) || userIds.length === 0) return;
      const selection = new Set(userIds);

      const alreadySelected = new Set(
        (type === TeamMemberType.ASSIGNED ? assignedMembers : invitedMembers).map((m) => m.usersId)
      );

      const newMembers = options!
        .filter(
          (o) =>
            o !== 'SEPARATOR' &&
            'extra' in o &&
            userIds.includes(o.id as number) &&
            !alreadySelected.has(o.id as number)
        )
        .map((o) => {
          const filteredOption = o as SimpleOption;
          const user = filteredOption.extra as User;

          const status =
            type === TeamMemberType.INVITED
              ? TeamMemberStatus.SELECTED
              : TeamMemberStatus.CONFIRMED;

          return {
            usersId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: user.fullName!,
            avatarStorageKey: user.avatarStorageKey!,
            status,
            internalComment: null,
            statement: null,
            invitations: [],
            assignmentNotifications: [],
            accreditations: [],
            availabilities: user.availabilities,
            labels: user.labels || [],
            alreadySavedRoles: null,
            roles: [],
            allowedRoles: getAllowedRolesFromAccreditations(user.accreditations || []),
          };
        });

      const newMemberIds = new Set(newMembers.map((m) => m.usersId));

      if (type === TeamMemberType.INVITED) {
        formikContext.setValues({
          ...formikContext.values,
          invitedMembers: [
            ...invitedMembers.filter((m) => selection.has(m.usersId)),
            ...newMembers,
          ],
          assignedMembers: formikContext.values.assignedMembers.filter(
            (m) => !newMemberIds.has(m.usersId)
          ),
        });
      } else {
        formikContext.setValues({
          ...formikContext.values,
          invitedMembers: formikContext.values.invitedMembers.filter(
            (m) => !newMemberIds.has(m.usersId)
          ),
          assignedMembers: [
            ...assignedMembers.filter((m) => selection.has(m.usersId)),
            ...newMembers,
          ],
        });
      }
    },
    [assignedMembers, invitedMembers, options, formikContext, type]
  );

  return (
    <Modal
      ariaLabel={t('New Team Member dropdown dialog')}
      onConfirm={() => add(selected)}
      onClear={() => setSelected([])}
      open={open}
      onClose={() => {
        setSelected([]);
        onClose();
      }}
    >
      <DropDown
        onChange={selectWithoutRemovingNotRemovableUsers}
        options={options || []}
        loading={!options}
        value={selected}
        mode="inline"
      />
    </Modal>
  );
};

export default AddModal;
