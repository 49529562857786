import useClientOption, { useBoolClientOption } from 'core/hooks/useClientOption';
import { AthleteDocumentNumber } from 'settings/models/Setting';

const useGetIsDocumentNumberOptional = (use3genApp?: boolean | null) => {
  const hideAthleteDocumentNumber = useBoolClientOption('hideAthleteDocumentNumber');
  const athleteDocumentNumber = useClientOption(
    'athleteDocumentNumber'
  ) as AthleteDocumentNumber | null;

  const isOptionalBySetting =
    athleteDocumentNumber && use3genApp //athleteDocumentNumber is implemented only for 3genApp
      ? athleteDocumentNumber !== AthleteDocumentNumber.MANDATORY
      : hideAthleteDocumentNumber;

  return (isAthleteIdProvided: boolean | null, isPersonallyKnown: boolean | null) => {
    const noAthleteIdAndKnown = !isAthleteIdProvided && isPersonallyKnown;

    return isOptionalBySetting || noAthleteIdAndKnown;
  };
};

export default useGetIsDocumentNumberOptional;
