import { FC } from 'react';

import { AccreditationsRole } from 'app/models/Accreditation';
import { FlexCell } from 'core/components/FlexUtils';

import { TeamMemberType } from '../../enums';
import { TeamMemberData } from '../../useTeamInputMapping';
import useActions from '../useActions';

import ActionsCell from './cells/ActionsCell';
import BasicInfoCell from './cells/BasicInfoCell';
import RolesCell from './cells/RolesCell';
import StatusCell from './cells/StatusCell';

interface Props {
  availabilities: { [usersId: number]: string };
  triggers: ReturnType<typeof useActions>['1'];
  /**
   * Do not allow remove already saved members & roles
   * user can add new, select additional role but once is saved, its disabled to remove Member or Role
   */
  disableRemoveRoles?: AccreditationsRole[];
  user: TeamMemberData & { id: number };
  advancedAssignmentEnabled: boolean;
  disableChangeLeadDco: boolean;
  fieldPrefix: TeamMemberType;
  hasBloodAnalysis: boolean;
  actionsDisabled: boolean;
  type: TeamMemberType;
  showStatus: boolean;
  isViewMode: boolean;
  index: number;
}

const MembersCardItem: FC<Props> = ({
  advancedAssignmentEnabled,
  disableChangeLeadDco,
  disableRemoveRoles,
  hasBloodAnalysis,
  actionsDisabled,
  availabilities,
  fieldPrefix,
  isViewMode,
  showStatus,
  triggers,
  index,
  user,
  type,
}) => {
  return (
    <>
      {/* Personal info */}
      <FlexCell flex={4} block minWidth="10rem">
        <BasicInfoCell user={user} availabilities={availabilities} />
      </FlexCell>

      {/* Status  */}
      <FlexCell flex={2} block minWidth="5rem">
        {showStatus && <StatusCell user={user} />}
      </FlexCell>

      {/* Roles */}
      <FlexCell flex={6} block minWidth="15rem">
        <RolesCell
          disableChangeLeadDco={disableChangeLeadDco}
          disableRemoveRoles={disableRemoveRoles}
          hasBloodAnalysis={hasBloodAnalysis}
          fieldPrefix={fieldPrefix}
          index={index}
        />
      </FlexCell>

      {/* Action */}
      <FlexCell flex={2} block>
        <ActionsCell
          advancedAssignmentEnabled={advancedAssignmentEnabled}
          actionsDisabled={actionsDisabled}
          availabilities={availabilities}
          isViewMode={isViewMode}
          triggers={triggers}
          user={user}
          type={type}
        />
      </FlexCell>
    </>
  );
};

export default MembersCardItem;
