import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { DataCardAction } from 'core/components/DataCard/props';
import { TeamMemberStatus } from 'planning/models/TeamMember';

import { TeamMemberType } from '../../enums';
import { TeamFormData, TeamMemberData } from '../../useTeamInputMapping';

import AddModal from './AddModal';
import CommentsModal from './CommentsModal';
import InvitationsModal from './InvitationsModal';

const useActions = (
  type: TeamMemberType,
  advancedAssignmentEnabled: boolean,
  notRemovableUsersId?: number[]
) => {
  const [togglingInvitation, setTogglingInvitation] = useState<TeamMemberData[]>();
  const [editingComments, setEditingComments] = useState<TeamMemberData>();
  const formik = useFormikContext<TeamFormData>();
  const [adding, setAdding] = useState(false);
  const { t } = useTranslation();
  const isInvitedType = type === TeamMemberType.INVITED;

  const oppositeDataKey = isInvitedType ? 'assignedMembers' : 'invitedMembers';
  const formData = formik.values[type];

  const toggleAssignment = (selection: TeamMemberData | TeamMemberData[]) => {
    const current = formik.values[type];
    const membersToToggle = Array.isArray(selection) ? selection : [selection];
    const userIds = membersToToggle.map((member) => member.usersId);

    // Check if we're trying to remove members that shouldn't be removed
    if (notRemovableUsersId?.length) {
      const isRemovingAssignedMembers = isInvitedType
        ? false
        : membersToToggle.some((member) => notRemovableUsersId.includes(member.usersId));

      if (isRemovingAssignedMembers) {
        toast.error(t('Already saved team members can not be removed'));
        return;
      }
    }

    // Create a set of user IDs for efficient lookup
    const userIdsSet = new Set(userIds);

    formik.setValues({
      ...formik.values,
      // Remove the toggled members from the current list
      [type]: current.filter((member) => !userIdsSet.has(member.usersId)),
      // Add the toggled members to the opposite list with appropriate status
      [oppositeDataKey]: [
        ...formik.values[oppositeDataKey],
        ...membersToToggle.map((member) => ({
          ...member,
          status:
            type === TeamMemberType.ASSIGNED
              ? TeamMemberStatus.SELECTED
              : TeamMemberStatus.CONFIRMED,
        })),
      ],
    });
  };

  const onRemove = (selection: TeamMemberData | TeamMemberData[]) => {
    const current = formik.values[type];
    const toDelete = new Set(
      Array.isArray(selection) ? selection.map((member) => member.usersId) : [selection.usersId]
    );

    if (notRemovableUsersId?.some((id) => toDelete.has(id))) {
      toast.error(t('Already saved team members can not be removed'));
      return;
    }

    formik.setValues({
      ...formik.values,
      [type]: current.filter((member) => !toDelete.has(member.usersId)),
    });
  };

  let actions: Array<
    DataCardAction<TeamMemberData & { id: number }> & {
      isAdvancedAssignment: boolean;
    }
  > = [
    {
      id: 'assignment',
      bulkIcon: 'person_add',
      title: type === TeamMemberType.INVITED ? t('Assign') : t('Unassign'),
      onClick: toggleAssignment,
      isAdvancedAssignment: true,
    },
    {
      id: 'invitation',
      bulkIcon: 'send',
      title: t('Plan Invitation'),
      onClick: (selection) =>
        setTogglingInvitation(Array.isArray(selection) ? selection : [selection]),
      isAdvancedAssignment: true,
    },
    {
      id: 'comment',
      title: t('Edit Internal Comments'),
      onClick: (selection) =>
        selection && !Array.isArray(selection) && setEditingComments(selection),
      isAdvancedAssignment: false,
    },
    {
      id: 'remove',
      title: t('Remove'),
      onClick: onRemove,
      isAdvancedAssignment: false,
    },
  ];

  actions = advancedAssignmentEnabled
    ? actions
    : actions.filter((action) => !action.isAdvancedAssignment);

  const triggers = {
    onToggleInvitations: (member: TeamMemberData) => setTogglingInvitation([member]),
    onEditComments: (member: TeamMemberData) => setEditingComments(member),
    onAdd: () => setAdding(true),
    onRemove,
  };

  const modals = (
    <>
      <AddModal
        notRemovableUsersId={notRemovableUsersId}
        onClose={() => setAdding(false)}
        open={adding}
        type={type}
      />
      <CommentsModal
        index={formData.findIndex((m) => m.usersId === editingComments?.usersId)}
        onClose={() => setEditingComments(undefined)}
        member={editingComments}
        type={type}
      />
      <InvitationsModal
        onClose={() => setTogglingInvitation(undefined)}
        members={togglingInvitation}
        type={type}
      />
    </>
  );

  return [actions, triggers, modals] as const;
};

export default useActions;
