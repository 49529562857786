import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  CountryRequired,
  useAddressSchema,
} from 'core/components/AddressesFields/useAddressesFieldsSchema';
import { getTabMeta } from 'core/components/Form/TabErrorCollector';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import useDateRangeSchema from 'core/hooks/useDateRangeSchema';
import usePermission from 'core/hooks/usePermission';
import useTimeRangeSchema from 'core/hooks/useTimeRangeSchema';
import { MAX_ZIP_LENGTH } from 'core/utils/constant';
import useAnalysesFieldsSchema from 'planning/components/AnalysesFields/useAnalysesFieldsSchema';
import useOfficersSchema from 'planning/components/OfficersFieldset/useOfficersSchema';
import useSettingsSchema from 'planning/components/SettingsTab/useSettingsSchema';
import useTeamMembersSchema from 'planning/components/TeamTab/useTeamMembersSchema';
import useDateToBufferSchema from 'planning/hooks/useDateBufferSchema';
import usePlannedAtSchema from 'planning/hooks/usePlannedAtSchema';

const tabMeta = getTabMeta<'detail' | 'settings'>();

const useMissionSchema = () => {
  const { t } = useTranslation();
  const canEditDetails = usePermission('missions:patch[actions:details]');
  const analysesSchema = useAnalysesFieldsSchema('detail', !canEditDetails);
  const dateRangeSchema = useDateRangeSchema();
  const [timeFromSchema, timeToSchema] = useTimeRangeSchema();
  const teamMembersSchema = useTeamMembersSchema('team', true);
  const advancedDcoAssignmentEnabled = useBoolClientOption('enableAdvancedDcoAssignment');
  const officersSchema = useOfficersSchema();
  const settingsSchema = useSettingsSchema('settings');
  const plannedAt = usePlannedAtSchema();
  const { dateToBufferSchema, dateFromBufferSchema } = useDateToBufferSchema();
  const addressFields = useAddressSchema({ countryRequired: CountryRequired.NEVER }, ['detail']);

  return useMemo(
    () =>
      analysesSchema
        .shape({
          timeZonesId: Yup.number().nullable().required(),
          assigneesId: Yup.number().nullable().required().meta(tabMeta('detail')).default(null),
          code: Yup.string()
            .nullable()
            .when('generateCode', {
              is: true,
              then: Yup.string().nullable().default(t('Autogenerated')),
              otherwise: (schema) => schema.required(),
            })
            .max(25)
            .meta(tabMeta('detail'))
            .default(null),
          generateCode: Yup.boolean().default(false),
          federationsName: Yup.string().nullable().max(200).meta(tabMeta('detail')).default(null),
          federationsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
          debtorsName: Yup.string().nullable().max(200).meta(tabMeta('detail')).default(null),
          debtorsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
          customersName: Yup.string().nullable().max(200).meta(tabMeta('detail')).default(null),
          customersId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
          agent: Yup.string().nullable().max(20).meta(tabMeta('detail')).default(null),
          sampleAuthoritiesId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
          initiatorAuthoritiesId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
          externalId: Yup.string().nullable().max(50).meta(tabMeta('detail')).default(null),
          testSituationsId: Yup.number()
            .nullable()
            .required()
            .meta(tabMeta('detail'))
            .default(null),
          poolsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
          testAuthority: Yup.number().nullable().required().meta(tabMeta('detail')).default(null),
          resultAuthority: Yup.number().nullable().required().meta(tabMeta('detail')).default(null),
          sportsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
          sportDisciplinesId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
          generalComments: Yup.string().nullable().meta(tabMeta('detail')).default(null),
          internalComments: Yup.string().nullable().meta(tabMeta('detail')).default(null),
          drawComments: Yup.string().nullable().meta(tabMeta('detail')).default(null),
          invoiceComments: Yup.string().nullable().meta(tabMeta('detail')).default(null),
          accommodationComments: Yup.string().nullable().meta(tabMeta('detail')).default(null),
          race: Yup.string().nullable().max(200).meta(tabMeta('detail')).default(null),
          place: Yup.string().nullable().max(100).meta(tabMeta('detail')).default(null),
          dateRange: dateRangeSchema.meta(tabMeta('detail')).required(),
          timeFrom: timeFromSchema.meta(tabMeta('detail')),
          timeTo: timeToSchema.meta(tabMeta('detail')),
          dateFromBuffer: dateFromBufferSchema.meta(tabMeta('detail')),
          dateToBuffer: dateToBufferSchema.meta(tabMeta('detail')),
          plannedAt: plannedAt.meta(tabMeta('detail')),
          ...(advancedDcoAssignmentEnabled ? teamMembersSchema : {}),
          ...(!advancedDcoAssignmentEnabled ? officersSchema : {}),
          zip: Yup.string().nullable().max(MAX_ZIP_LENGTH).meta(tabMeta('detail')).default(null),
          eventName: Yup.string().nullable().max(200).meta(tabMeta('detail')).default(null),
          eventsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),

          createLocation: Yup.boolean().meta(tabMeta('detail')).default(false),
          locationsId: Yup.number().meta(tabMeta('detail')).nullable(),
          locationName: Yup.string()
            .nullable()
            .when('createLocation', {
              is: true,
              then: (schema) => schema.required(),
            })
            .meta(tabMeta('detail'))
            .default(null),

          costsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
          invoicingCodesId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        })
        .concat(settingsSchema)
        .concat(addressFields),
    [
      analysesSchema,
      addressFields,
      t,
      dateRangeSchema,
      timeFromSchema,
      timeToSchema,
      dateFromBufferSchema,
      dateToBufferSchema,
      plannedAt,
      advancedDcoAssignmentEnabled,
      teamMembersSchema,
      settingsSchema,
      officersSchema,
    ]
  );
};

export type MissionFormData = Yup.TypeOf<ReturnType<typeof useMissionSchema>>;
export type ValidMissionFormData = Yup.Asserts<ReturnType<typeof useMissionSchema>>;

export default useMissionSchema;
