import { useMemo } from 'react';
import * as Yup from 'yup';

import { getTabMeta } from 'core/components/Form/TabErrorCollector';
import { MAX_BCOS, MAX_DCOS } from 'planning/models/Mission';

import { useSingleMemberSchema } from '../TeamTab/useTeamMembersSchema';

const tabMeta = getTabMeta<'detail'>();

const useOfficersSchema = () => {
  const singleMemberSchema = useSingleMemberSchema('team', false);

  const membersSchema = useMemo(
    () => Yup.array(singleMemberSchema).meta(tabMeta('detail')).ensure().default([]),
    [singleMemberSchema]
  );

  return useMemo(
    () => ({
      invitedMembers: membersSchema,
      assignedMembers: membersSchema,
      dcos: Yup.array(Yup.number().default(0))
        .nullable()
        .max(MAX_DCOS)
        .meta(tabMeta('detail'))
        .default(null),
      bcos: Yup.array(Yup.number().default(0))
        .nullable()
        .max(MAX_BCOS)
        .meta(tabMeta('detail'))
        .default(null),
      chaperones: Yup.array(Yup.number().default(0))
        .nullable()
        .max(MAX_BCOS)
        .meta(tabMeta('detail'))
        .default(null),
      leadDcosId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
    }),
    [membersSchema]
  );
};

export default useOfficersSchema;
