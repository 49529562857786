import { useCallback, useMemo } from 'react';

import { useGetPermission } from 'core/hooks/usePermission';
import { useResources } from 'core/hooks/useResource';
import Analysis from 'lists/models/Analysis';
import { TeamMemberStatus } from 'planning/models/TeamMember';
import { TestStatus } from 'planning/models/Test';
import {
  BLOOD_ANALYSES_URL,
  DBS_ANALYSES_URL,
  URINE_ANALYSES_URL,
} from 'planning/resources/analyses';

import useIncludeOverride from '../../hooks/useIncludeOverride';
import { TestFormData } from '../../hooks/useTestSchema';

type FormKeys = keyof TestFormData;

export type TestStatusEditableTestFields = { [key in TestStatus]?: FormKeys[] };

export type ViewMode =
  | Partial<{
      [key in FormKeys]: boolean;
    }>
  | undefined;

function getViewModeFromEditableFields(
  editableFields: TestStatusEditableTestFields,
  status: TestStatus
): ViewMode {
  let result: Partial<{
    [key in FormKeys]: boolean;
  }> = {};
  editableFields[status]?.forEach((field) => {
    result[field] = false;
  });
  return result;
}

/**
 * Define view mode for the test fields for each test status
 * @returns Callback that takes test status as a single parameter and returns `ViewMode`.
 * The response from the callback will be:
 *  - `undefined` if there are no restrictions for the editable fields,
 *  - an empty object if the user should not be able to edit anything,
 *  - an object with field names each mapped to `false` value
 */
const useGetViewMode = (): ((
  testStatus?: TestStatus,
  teamMemberStatus?: TeamMemberStatus
) => ViewMode) => {
  const hasPermission = useGetPermission();

  const { data: blood } = useResources<Analysis>(BLOOD_ANALYSES_URL, { autoload: false });
  const { data: urine } = useResources<Analysis>(URINE_ANALYSES_URL, { autoload: false });
  const { data: dbs } = useResources<Analysis>(DBS_ANALYSES_URL, { autoload: false });
  const [includes] = useIncludeOverride();

  const otherBloodAnalysesCount = useMemo(
    () =>
      (blood?.length ? blood : (includes?.bloodAnalyses as Analysis[]) || []).filter((it) =>
        it.code.startsWith('ADT')
      ).length,
    [blood, includes?.bloodAnalyses]
  );

  const otherUrineAnalysesCount = useMemo(
    () =>
      (urine?.length ? urine : (includes?.urineAnalyses as Analysis[]) || []).filter((it) =>
        it.code.startsWith('ADT')
      ).length,
    [urine, includes?.urineAnalyses]
  );

  const otherDbsAnalysesCount = useMemo(
    () =>
      (dbs?.length ? dbs : (includes?.dbsAnalyses as Analysis[]) || []).filter((it) =>
        it.code.startsWith('ADT')
      ).length,
    [dbs, includes?.dbsAnalyses]
  );

  return useCallback(
    (testStatus?: TestStatus, teamMemberStatus?: TeamMemberStatus) => {
      // if the test status is undefined, return undefined, this should not happen
      if (testStatus === undefined) {
        return {};
      }

      const canEditDetails = hasPermission('tests:patch[actions:details]');
      const canEditStatement = hasPermission('tests:patch[actions:statement]');

      // if the user does not have permission to edit details or statement, they should not be able to edit any fields
      if (!canEditDetails && !canEditStatement) {
        return {};
      }

      const canOnlyEditStatement = canEditStatement && !canEditDetails;

      // if the user can only edit statement and a team member, and they are not yet confirmed, they should not be able to edit anything
      if (
        canOnlyEditStatement &&
        teamMemberStatus &&
        teamMemberStatus !== TeamMemberStatus.CONFIRMED
      ) {
        return {};
      }

      // if the user can only edit statement, and is assigned, they should be able to edit the plannedAt field for unassigned and assigned tests
      if (canOnlyEditStatement) {
        const editableFields = {
          ...Object.fromEntries(
            [TestStatus.UNASSIGNED, TestStatus.ASSIGNED].map((testStatus) => [
              testStatus,
              ['plannedAt'],
            ])
          ),

          ...Object.fromEntries(
            [
              TestStatus.IN_PROGRESS,
              TestStatus.COMPLETED,
              TestStatus.CANCELLED,
              TestStatus.UNSUCCESSFUL,
            ].map((testStatus) => [testStatus, []])
          ),
        };
        return getViewModeFromEditableFields(editableFields, testStatus);
      }

      // if the user can edit details and the test status is assigned or unassigned, they should be able to edit everything
      if (canEditDetails && [TestStatus.ASSIGNED, TestStatus.UNASSIGNED].includes(testStatus)) {
        return undefined;
      }

      // if the user can edit details and the test status is in progress, completed, cancelled, or unsuccessful, they should be able to edit specific fields only
      if (
        canEditDetails &&
        [
          TestStatus.IN_PROGRESS,
          TestStatus.COMPLETED,
          TestStatus.CANCELLED,
          TestStatus.UNSUCCESSFUL,
        ].includes(testStatus)
      ) {
        const editableFields = {
          ...Object.fromEntries(
            [
              TestStatus.IN_PROGRESS,
              TestStatus.COMPLETED,
              TestStatus.CANCELLED,
              TestStatus.UNSUCCESSFUL,
            ].map((testStatus) => [
              testStatus,
              [
                'federationsId',
                'federationsName',
                'debtorsId',
                'debtorsName',
                'customersId',
                'customersName',
                'testAuthority',
                'resultAuthority',
                'sampleAuthoritiesId',
                'initiatorAuthoritiesId',
                'externalId',
                'labels',
                'bloodAnalyses',
                'bloodLabsId',
                'bloodExpedited',
                'bloodLts',
                'bloodComments',
                'urineAnalyses',
                'urineLabsId',
                'urineExpedited',
                'urineLts',
                'urineComments',
                'dbsAnalyses',
                'dbsLabsId',
                'dbsExpedited',
                'dbsLts',
                'dbsComments',
                'costsId',
                'invoicingCodesId',
                'generalComments',
                'invoiceComments',
                'useAdamsAthleteLevel',
                'adamsAthleteLevelsId',
                ...Array.from({ length: otherBloodAnalysesCount }, (_, i) => i)
                  .map((i) => [`otherBloodAnalyses[${i}].id`, `otherBloodAnalyses[${i}].remarks`])
                  .flat(),
                ...Array.from({ length: otherUrineAnalysesCount }, (_, i) => i)
                  .map((i) => [`otherUrineAnalyses[${i}].id`, `otherUrineAnalyses[${i}].remarks`])
                  .flat(),
                ...Array.from({ length: otherDbsAnalysesCount }, (_, i) => i)
                  .map((i) => [`otherDbsAnalyses[${i}].id`, `otherDbsAnalyses[${i}].remarks`])
                  .flat(),
                'invitedMembers',
                'assignedMembers',
              ],
            ])
          ),
        };

        const result = getViewModeFromEditableFields(editableFields, testStatus);

        return result;
      }

      // not able to edit anything by default
      return {};
    },
    [hasPermission, otherBloodAnalysesCount, otherUrineAnalysesCount, otherDbsAnalysesCount]
  );
};

export default useGetViewMode;
