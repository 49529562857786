import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { AccreditationsRole } from 'app/models/Accreditation';
import Tooltip from 'core/components/Tooltip';
import { getParticipantsMap } from 'finance/containers/DCOInvoiceDetailPage/hooks/useTestPopover';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';

interface Props {
  disableChange?: boolean;
  role: AccreditationsRole;
  disableRemove: boolean;
  children: ReactElement;
  value?: TeamMemberData;
}

const RoleTooltip: FC<Props> = ({ disableChange, disableRemove, children, role, value }) => {
  const isNotAllowed = value && !value.allowedRoles.includes(role);
  const { t } = useTranslation();

  let tooltip;

  if (isNotAllowed) {
    tooltip = t('User has no Accreditation for this role.');
  } else if (disableChange) {
    tooltip = t('Cannot change {{role}} role.', {
      role: getParticipantsMap(role),
    });
  } else if (disableRemove) {
    tooltip = t("It's not possible to remove saved role.");
  }

  if (tooltip) {
    return <Tooltip content={tooltip}>{children}</Tooltip>;
  }

  return <>{children}</>;
};

export default RoleTooltip;
