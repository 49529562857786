import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { AccreditationsRole } from 'app/models/Accreditation';
import { getTabMeta } from 'core/components/Form/TabErrorCollector';
import { getParticipantsMap } from 'finance/containers/DCOInvoiceDetailPage/hooks/useTestPopover';

import { AnalysesFieldsData } from '../AnalysesFields';

import { NotificationType } from './enums';
import { TeamMemberData } from './useTeamInputMapping';

const tabMeta = getTabMeta();

export const useSingleMemberSchema = (
  section: string,
  /** expect that this schema is used within AnalysesFields */
  checkUrineOnly: boolean
) => {
  const { t } = useTranslation();
  const metaTab = useMemo(() => (section ? tabMeta(section) : {}), [section]);

  const notificationSchema = Yup.array(
    Yup.object({
      type: Yup.string().oneOf([NotificationType.SMS, NotificationType.EMAIL]).meta(metaTab),
      sentType: Yup.string().oneOf([NotificationType.SMS, NotificationType.EMAIL]).meta(metaTab),
      sentAt: Yup.date().meta(metaTab),
      createdAt: Yup.date().meta(metaTab),
    }).meta(metaTab)
  )
    .ensure()
    .meta(metaTab)
    .default([]);

  return useMemo(
    () =>
      Yup.object({
        usersId: Yup.number().required().meta(metaTab),
        status: Yup.number().meta(metaTab),
        internalComment: Yup.string().nullable().meta(metaTab).default(null),
        statement: Yup.string().nullable().meta(metaTab).default(null),
        roles: Yup.array<AccreditationsRole[]>(Yup.mixed<AccreditationsRole>().meta(metaTab))
          .required()
          .meta(metaTab)
          .test(
            'accredited',
            t('Not accredited for the selected roles'),
            function accredited(value: any) {
              const allowedRoles: number[] = this.parent.allowedRoles || [];
              const hasAll =
                value &&
                Array.isArray(value) &&
                value.every((pickedRole) => allowedRoles.includes(pickedRole));
              return hasAll;
            }
          )
          .test(
            'disabledBCORole',
            t('The {{bco}} role is not allowed for Urine Only tests.', {
              bco: getParticipantsMap(AccreditationsRole.BCO),
            }),
            function disabledBCORole(value) {
              if (!checkUrineOnly || !value || !value.includes(AccreditationsRole.BCO)) {
                return true;
              }
              // @ts-expect-error make no sense to type this
              const formData = this.from[1].value as unknown as undefined | AnalysesFieldsData;

              return (
                Number(formData?.bloodAnalyses?.length) > 0 ||
                Number(formData?.otherBloodAnalyses?.length) > 0 ||
                Number(formData?.dbsAnalyses?.length) > 0 ||
                Number(formData?.otherDbsAnalyses?.length) > 0
              );
            }
          )
          .test(
            'requireDCOForLeadDCO',
            t('{{lead}} Cannot be assigned without role {{dco}}', {
              lead: getParticipantsMap(AccreditationsRole.LEAD_DCO),
              dco: getParticipantsMap(AccreditationsRole.DCO),
            }),
            function (roles, { parent }) {
              return (
                !roles || // no value
                !roles.includes(AccreditationsRole.LEAD_DCO) || // not lead
                roles.includes(AccreditationsRole.DCO) || // dco role is assigned
                !parent.allowedRoles.includes(AccreditationsRole.DCO) // assigned LEAD has no DCO role - MODFE-3614
              );
            }
          )
          .min(1)
          .ensure()
          .default([]),
        invitations: notificationSchema,
        assignmentNotifications: notificationSchema,
        // Read-only data that are passed around
        firstName: Yup.string().nullable().meta(metaTab),
        lastName: Yup.string().nullable().meta(metaTab),
        fullName: Yup.string().nullable().meta(metaTab),
        avatarStorageKey: Yup.string().nullable().meta(metaTab),
        availabilities: Yup.array().meta(metaTab),
        labels: Yup.array().meta(metaTab),
        allowedRoles: Yup.array(Yup.string()).meta(metaTab).nullable().default([]),
        alreadySavedRoles: Yup.array(Yup.string()).meta(metaTab).nullable().default(null),
      })
        .test('leadDco', t('There can be only one Lead DCO'), function (value) {
          const members = this.parent;
          if (!members) return true;

          const leadCount = members.reduce((acc: number, member: TeamMemberData) => {
            if (member.roles.includes(AccreditationsRole.LEAD_DCO)) acc++;
            return acc;
          }, 0);

          return value.roles.includes(AccreditationsRole.LEAD_DCO) && leadCount > 1
            ? this.createError({ path: `${this.path}.roles` })
            : true;
        })
        .meta(metaTab),
    [metaTab, t, notificationSchema, checkUrineOnly]
  );
};

export default function useTeamMembersSchema(section: string, checkUrineOnly: boolean) {
  const singleMemberSchema = useSingleMemberSchema(section, checkUrineOnly);
  const metaTab = useMemo(() => (section ? tabMeta(section) : {}), [section]);

  const membersSchema = useMemo(
    () => Yup.array(singleMemberSchema).meta(metaTab).ensure().default([]),
    [singleMemberSchema, metaTab]
  );

  return useMemo(
    () => ({
      invitedMembers: membersSchema,
      assignedMembers: membersSchema,
      notifyOfTeamUpdates: Yup.boolean().meta(metaTab).default(false),
      showAthleteToDco: Yup.boolean().meta(metaTab).default(false),
    }),
    [metaTab, membersSchema]
  );
}
